@import 'src/style/mixins';

.Container {
  margin: 0 auto;
  list-style: none;
  overflow-y: scroll;
  padding: 1px 0 4px;
  color: $color-black3;
  height: calc(100% - 58px);
  li > div {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-column-gap: 9px;
    padding-left: 37px;
    align-self: center;
    align-items: center;
    height: 40px;
    font-size: 14px;
    text-transform: uppercase;
    margin: 4px 0;
  }

  li:hover,
  &:focus {
    text-decoration: none;
    color: $color-blue2;
    background-color: $color-blue3;
    cursor: pointer;
  }
}

.Row:hover:focus {
  text-decoration: none;
  color: $color-blue2;
  background-color: $color-blue3;
  cursor: pointer;
}

.Name {
  height: 100%;
}

.Title {
  border-bottom: 1px solid $color-black5;
  margin-bottom: 10px;
  background-color: white;
  top: 0;
  overflow-x: hidden;
  width: 100%;
  h3 {
    color: $color-black0;
    margin-bottom: 10px;
    margin-left: 18px;
    font-size: 14px;
    font-weight: $fontWeights-medium;
  }
}

.Highlight {
  text-decoration: none;
  color: $color-blue2;
  background-color: $color-blue3;
  cursor: pointer;
}

.CurrencySymbol {
  justify-self: center;
}
