@import 'src/style/mixins';

.Item {
  padding: 10px 40px;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  &.Active {
    background-color: $color-blue3;
    position: relative;
    z-index: -1;

    &::before {
      position: absolute;
      content: "";
      width: 4px;
      background-color: $color-blue2;
      left: 0;
      top: 0;
      bottom: 0;
    }
    
  }
}
