@import 'src/style/mixins';

.SampleButton {
  font-weight: 100;
  font-size: 12px;
  height: 40px;
  text-align: center;
  @include desktop {
    height: 30px;
    font-size: 14px;
  }
}
