@import 'src/style/mixins';

.Button {
  position: inherit;
  bottom: 14px;
  right: 17px;
  height: 30px;
  padding: 0;
  font-weight: 100;
  font-size: 14px;
  border-radius: 5px;
  padding: 0 15px;

  &.ButtonBasic {
    background: $color-blue1;
  }
}
