@import 'src/style/mixins';

.HeaderContainer {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 7;
}

.HeaderDesktop {
  background-color: $color-black6;
  display: none;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  @include desktop {
    display: flex;
  }

  .Left {
    display: flex;
    align-items: center;

    .TypeMenu {
      display: flex;
      gap: 5px;
      margin-left: 10px;

      .Item {
        padding: 2px 15px;
        border-radius: $borderRadius;
        font-weight: 100;
        cursor: pointer;
        transition: all 0.15s;
        border: 1px solid transparent;
        box-sizing: border-box;
        height: 28px;

        &:hover {
          background-color: $color-black4;
          border: 1px solid $color-black3;
        }

        &.Active {
          background-color: $color-black4;
        }
      }
    }
  }
  .Right {
    display: flex;
    align-items: center;
    .Item {
      padding: 2px 10px;
      border-radius: $borderRadius;
      font-weight: 100;
      cursor: pointer;
      transition: all 0.15s;
      border: 1px solid transparent;

      &:hover {
        background-color: $color-black4;
        border: 1px solid $color-black3;
      }
    }

    @include desktop {
      gap: 6px;
    }
  }
}
.HeaderMobile {
  background-color: $color-black6;
  display: flex;
  padding: 10px 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 7;

  @include tablet {
    padding: 10px 20px;
  }

  @include desktop {
    display: none;
  }

  .Left {
    display: flex;
    align-items: center;

    .TypeMenu {
      display: flex;
      margin-left: 10px;

      .Item {
        padding: 5px 10px;
        border-radius: 10px;
        font-weight: 100;

        &.Active {
          background-color: $color-black7;
        }
      }
    }
  }
  .Right {
    display: flex;
    align-items: center;
    .Icon {
      display: flex;
      .SearchIcon {
        height: 16px;
      }
    }
    .Item {
      padding: 5px 10px;
      border-radius: 10px;
      font-weight: 100;
    }
  }
}

.HeaderDesktop .Item.Profile,
.HeaderMobile .Item.Profile {
  display: flex;
  img {
    width: 13px;
    height: auto;
  }
}

.HeaderMobile .Item.Profile {
  background-color: $color-black4;
  width: 27px;
  height: 27px;
  justify-content: space-around;
  border-radius: 100%;
}

.TypeSubMenu {
  display: none;
  border-bottom: 1px solid $color-black7;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: $color-white;
  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet {
    display: flex;
  }

  @include desktop {
    overflow: visible;
    white-space: normal;
  }

  .Item {
    padding: 8px 15px;
    font-size: 14px;
    color: #707070;
    font-weight: 100;
    cursor: pointer;
    transition: background-color 0.15s;

    &:hover {
      background-color: $color-black7;
    }

    &.Active {
      color: $color-blue1;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background-color: $color-blue1;
        left: 0;
        bottom: -1px;
      }
    }
  }
}

.TypeSubMenuAccount {
  display: flex;
}

.PurchaseButton {
  display: block;
}
