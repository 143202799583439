@import "src/style/mixins";

.Container {
  background: $color-white;
  padding: 18px 42px;
  text-align: center;

  @include tablet {
    border: 1px solid $color-black4;
    border-radius: 25px;
    max-width: 548px;
    max-height: 207px;
    padding: 24px 8px 21px;
    flex: 1;
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:
    "image text"
    "image text";
  }

  @include desktop {
    display: grid;
    max-width: 363px;
    grid-template-columns: 100%;
    grid-gap: 10px;
    grid-template-areas:
    "image"
    "text";

    justify-items: left;
    transition: box-shadow 0.5s ease-in-out;
    cursor: pointer;
    &:hover {
      box-shadow: 0 10px 30px rgba($color-black0, 0.16);
    }
  }
}

.Icon {
  display: block;

  &.ScoreIcon {
    width: 36px;
  }

  @include tablet {
    display: none;
  }
}

.Title {
  align-items: center;
  font-size: 24px;
  font-weight: $fontWeights-semiBold;
  margin-top: 5px;

   @include tablet {
    text-align: left;
  }

  @include tablet {
    display: flex;
    margin-top: 0;
    font-size: 18px;
    position: relative;
    justify-content: center;
    .RightArrow {
      display: none;
    }
  }
}

.Description {
  font-weight: $fontWeights-light;

  .long {
    display: none;
  }

  @include tablet {
    text-align: left;
    margin-right: 25px;

    .long {
      display: inline;
    }
    .short {
      display: none;
    }
  }

    @include desktop {
    padding: 0px 19px;
    margin-right: 0;
    text-align: center;
  }
}

.IconTitleContainer {
  display: grid;
  grid-template-rows: 26px 1fr;

  @include tablet {
    justify-self: left;
  }

  @include desktop {
    align-items: center;
    width: 100%;
    grid-template-rows: none;
    grid-column-gap: 10px;
    line-height: 1;
  }
}

.TabletImage {
  display: none;

  @include tablet {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 157px;
    margin-bottom: 19px;
    transform: scale(0.8);
    grid-area: image;
  }
}

.CompetitiveCard {
  pointer-events: none;
  display: none;

  .RightArrow {
    display: none;
  }

  @include tablet {
    pointer-events: auto;
    display: grid;

    .Icon {
      content: url("/images/icons/competitive-intelligence-dashboard-icon.svg");
    }
  }
}

.CardNewBadge {
  display: none;

  @include tablet {
    display: flex;
    position: absolute;
    right: -9px;
    height: 33px;
    top: 40px;
  }
}

.CardNewBadge {
  display: none;

  @include desktop {
    display: flex;
    position: absolute;
    right: -9px;
    height: 33px;
    top: 40px;
  }
}

.IconTitleBeta {
  display: none;

  @include tablet {
    display: flex;
    position: absolute;
    font-size: 8px;
    font-weight: $fontWeights-medium;
    right: 2px;
    top: 22px;
  }

  @include desktop {
    right: 24px;
    top: 22px;
  }
}
