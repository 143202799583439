@import "src/style/mixins";

.Container {
  display: grid;
  background-color: #d0e6fe;
  grid-template-areas:
    "image text"
    "button button";
    @include tablet {
      grid-template-areas: "image text button";
      border-radius: 6px;
      align-items: center;
      min-height: 126px;
      width: 100%;
  }
}

.Image {
  grid-area: image;
  margin-left: 19.6px;
  transform: scale(0.9);
  align-self: self-end;
  transform-origin: bottom;
  @include tablet {
    transform: scale(1);
  }
}

.TextContainer {
  display: block;
  text-align: left;
  margin: 10px;

  label {
    font-size: 14px;
    color: $color-blue1;
  }

  p {
    color: $color-blue2;
    font-size: 11px;
    margin: 0;
    font-weight: $fontWeights-light;
    text-justify: auto;
  }

  @include tablet {
  margin: 10px;

    label {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
}

.Button {
  grid-area: button;
  border-radius: 0;
  height: 50px;
  font-size: 14px;

  @include tablet {
    margin-right: 31px;
    margin-left: 15px;
    border-radius: 6px;

  }
}
