@import 'src/style/mixins';

.Container {
  display: block;
  position: fixed;
  background-color: $color-blue1;
  padding: 12px;
  z-index: 0;
  color: #fff;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  border-radius: 4px;
  transition: opacity 350ms;
  opacity: 0;
  min-width: 300px;
  top: 69%;
  left: 5%;

  &.Active {
    opacity: 1;
    z-index: 99;
  }

  @include tablet {
    width: fit-content;
    max-width: 1200px;
    top: 85%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .InfoContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .CloseIcon {
    display: none;
    @include tablet {
      display: block;
      margin-top: 3px;
      cursor: pointer;
    }
  }
}

.FreeSnackbar {
  top: 59%;
  @include tablet {
    top: 85%;
  }
}
