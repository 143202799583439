@import 'src/style/mixins';

.Title {
  padding: 0;
  margin: 0;
  font-weight: $fontWeights-semiBold;
  font-size: 18px;
  @include tablet {
    font-size: 28px;
  }
}

.Header {
  display: none;

  @include tablet {
    display: block;
  }
}

.Container {
  padding: 35px 0;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;

  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include desktop {
    padding-top: 25px;
  }
}

.Background {
  height: 100vh;
  background-size: 257% 67%;
  background-repeat: no-repeat;
  position: relative;
  background-position: bottom;
  opacity: 3;
  background-image: url('/images/backgrounds/home.svg');

  @include tablet {
    background-size: 120% 60%;
    height: 100%;
  }

  @include desktop {
    background-size: 100% 80%;
    height: 95vh;
    overflow: hidden;
    overflow-y: hidden;
  }
}

.TextTitle,
.SubTitle {
  padding-left: 40px;
  padding-right: 40px;

  @include tablet {
    width: 100%;
  }

  @include desktop {
    padding-left: 33px;
    padding-right: 33px;
  }
}

.TextTitle {
  font-size: 24px;
  font-weight: $fontWeights-bold;
  margin-top: 14px;
  margin-bottom: 27px;
  text-align: left;

  @include tablet {
    margin-bottom: 5px;
    text-align: center;
  }

  @include desktop {
    line-height: 1;
  }
}

.Card {
  margin-top: 10px;
  justify-items: center;
  text-align: left;

  @include tablet {
    min-height: 207px;
    max-width: 548;
  }

  @include desktop {
    max-height: 375px;
  }
}

.SubTitle {
  display: none;
  font-weight: $fontWeights-light;

  @include tablet {
    display: block;
    margin-bottom: 20px;
  }
}

.CardsContainer {
  @include desktop {
    display: flex;

    .Card:not(:first-of-type) {
      margin-left: 10px;
    }
  }
}

.EnterpriseBanner {
  margin-top: 29px;
}

.SearchBarContainer {
  padding: 0 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;

  @include tablet {
    padding: 0;
    width: 80%;
  }
}

.GeocodedAutocomplete {
  width: 100%;
  @include tablet {
    min-width: 548px;
  }
}
