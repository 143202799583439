@import 'src/style/mixins';

.Container {
  table tbody,
  table thead {
    display: block;
    width: 100%;
    border-collapse: collapse;
  }

  table tbody {
    overflow: auto;
    max-height: calc(100vh - 250px);
    @include tablet {
      max-height: 300px;
    }
  }

  table {
    width: 100%;
    border-spacing: 0px;
  }

  th {
    text-align: center;
    padding: 17px;
    font-weight: $fontWeights-medium;
    background-color: $color-black6;
  }

  td {
    padding: 1px 0 4px;
    margin-bottom: 2px;
    margin-top: 2px;
    height: 40px;
    font-size: 13px;

    &.Listings {
      padding: 0;
    }
  }

  th,
  td {
    padding: 5px 19px;
    min-width: 150px;
    width: 100%;
  }

  td:nth-last-child(1) {
    text-align: center;
  }

  th:nth-last-child(2) {
    text-align: left;
  }
}

.List {
  min-height: 40px;
  font-size: 13px;
  color: $color-black0;
  overflow: scroll;
  white-space: normal;

  &:hover,
  :focus {
    text-decoration: none;
    color: $color-blue2;
    background-color: $color-blue3;
    cursor: pointer;
  }
}

.Active {
  color: $color-blue2;
  background-color: $color-blue3;
}

.ListLabels {
  top: 0;
  z-index: 2;
  position: sticky;
}
