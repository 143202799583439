@import 'src/style/mixins';

$sideItemHeight: 48px;
$sideMenuWidth: 63px;
$sideMenuWidthExpanded: $sideMenuWidth;
$mobile-header-size: 66px;

.SideMenu {
  display: flex;
  position: fixed;
  transform: translateX(-390px);
  z-index: 21;
  flex-direction: column;
  height: 100%;
  top: $mobile-header-size;
  background-color: $color-white;
  top: 0;
  left: 0;

  .Badge {
    height: 5px;
    width: 5px;
    background-color: $color-pred;
    border-radius: 100%;
    position: absolute;
    top: 23px;
    left: 27px;
  }

  @include tablet {
    z-index: 13;
  }

  &.active {
    width: $sideMenuWidthExpanded__mobile;
    .Badge {
      top: 29px;
    }
  }

  &.MobileActive {
    transform: translateX(0px);
  }

  @include desktop {
    display: none;
  }
}

.GrayBlur {
  display: block;
  content: ' ';
  position: fixed;
  backdrop-filter: blur(2.5px);
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: calc(100vw - #{$sideMenuWidthExpanded__mobile});
  right: 0;
  top: 0;
  z-index: 22;

  @include desktop {
    display: none;
  }
}

.LogoContainer {
  @include FlexVCenter;
  overflow: hidden;
  margin-left: -8px;
  height: $mobile-header-size;
  @include tablet {
    height: $sideBars__desktop;
  }
}

.IsMenuOpen {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.BurguerButton {
  cursor: pointer;
  height: 19px;
  width: 19px;
}

.Hidden {
  display: none;
}

.ChevronContainer {
  display: none;
  @include tablet {
    cursor: pointer;
    width: 14px;
    height: 17px;
    background-color: $color-black6;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-left: $sideMenuBars__desktop - 14px;
    z-index: 10;
    &:hover {
      background-color: $color-black4;
    }
    &.active {
      margin-left: calc($sideMenuWidthExpanded__desktop - 14px);
      svg {
        transform: rotate(90deg);
      }
    }

    svg {
      transform: rotate(270deg);
    }
  }
}

.DateContainer {
  display: none;
  margin-left: 14px;
  margin-bottom: 12px;
  position: fixed;
  bottom: 8px;
  left: 3px;
  font-size: 12px;
  font-weight: $fontWeights-light;
  color: $color-black2;
  p {
    padding: 0;
    margin: 0;
    font-weight: $fontWeights-semiBold;
  }
  &.active {
    display: block;
  }
}

.HelpMenuContainer {
  position: fixed;
  width: 48px;
  bottom: 8px;
  left: 20px;
}

.MenuContainer {
  .Item {
    padding: 10px 16px;
    display: flex;
    font-weight: $fontWeights-semiBold;
    font-size: 14px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    position: relative;

    img {
      height: 11px;
      width: 11px;
    }

    &.Active {
      box-shadow: 0px 5px 6px #0000001f;
    }

    &.Sub {
      background-color: #f6f7f9;
      margin: 2px 0;
    }

    svg {
      margin-left: 10px;
    }
  }
  a {
    text-decoration: none;
  }
}

.MenuButton {
  display: flex;
  align-items: center;
  @include interactive;
  height: $sideMenuBars__desktop;
  border-left: 3px solid $color-white;
  font-weight: $fontWeights-regular;
  position: relative;
  color: $color-black0;
  overflow: hidden;

  &:hover {
    color: $color-blue1;
    .IconText {
      color: $color-blue1;
    }
    .SvgIcon {
      stroke: $color-blue1;
      fill: $color-blue1;
    }
  }

  &.active {
    border-left-color: $color-blue1;
    color: $color-blue1;
    .SvgIcon {
      stroke: $color-blue1;
      fill: $color-blue1;
    }
  }
}

.SvgIcon {
  display: block;
  stroke: $color-black0;
  fill: $color-black0;
  margin-left: 12px;
  margin-right: 12px;

  path#Path_2148 {
    fill: $color-black0;
  }
}

.IconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.IconText {
  font-size: 7px;
  color: $color-black0;

  &.active {
    color: $color-blue1;
  }
}

.Title {
  &.active {
    display: none;
  }
}

.MenuButtonCompetitors {
  display: none;
  @include tablet {
    display: flex;
  }
}

.Hidden {
  display: none;
}

.PagesLinks {
  position: fixed;
  width: 100%;

  &.active {
    margin-top: 10px;
  }
}

.SubMenuButtonText {
  width: 250px;
  @include ellipsis;

  @include tablet {
    width: 150px;
  }
}

.PopOver {
  color: $color-blue4;
  font-weight: $fontWeights-regular;
}

.PopOverBox {
  max-width: 170px;
}

.PopOverSmallBox {
  max-width: 120px;
}

.MenuButtons {
  overflow: hidden;
}

.CategoryTitle {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  overflow: hidden;
}

.MarketingCategoryTitle {
  @extend .CategoryTitle;
  margin: 13px 0 5px 13px;
}

.PageLinkCategoryTitle {
  @extend .CategoryTitle;
  margin: 10px 0 0 13px;

  @include tablet {
    margin: 13px 0 5px 13px;
  }
}

.MenuIconWrapper {
  display: flex;
  align-items: center;
}

.ChevronContainerMenu {
  width: 32px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.CurrencyMenuContainer {
  position: fixed;
  width: 48px;
  bottom: 8px;
  left: 3px;

  &.active {
    bottom: 18px;
    left: 255px;
    @include tablet {
      left: 165px;
    }
  }
}

.Close {
  font-size: 48px;
  font-weight: 100;
  color: #16191f;

  .CloseButton {
    width: 40px;
    height: 40px;
  }
}
