@import 'src/style/mixins';

.Container {
  position: relative;
  z-index: 11;
  align-items: center;
  background-color: $color-white;
  display: grid;
  border-bottom: 1px solid $color-black5;
  justify-content: center;
  grid-template-columns: 50px 1fr 86px;
  height: 66px;
  @include tablet {
    display: none;
    z-index: 20;
  }
}

.CenterContainer {
  @include FlexCenterCenter;
}

.RightContainer {
  margin-right: 17px;
}

.MenuButton {
  height: 66px;
  width: 66px;
}

.Flex {
  display: flex;
  cursor: pointer;
}
