@import 'src/style/mixins';


.Box{
	width: 340px;
	height: 347px;
}

.CloseButton{
	margin-top: 6px;
}