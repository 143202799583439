@import 'src/style/mixins';

.ModalContainer {
  position: fixed;
  z-index: 30;
  @include FlexCenterCenter;
}

.ModalBackground {
  position: absolute;
  width: 1285px;
  backdrop-filter: blur(2.5px);
  background-color: rgba(0, 0, 0, 0.3);
}

.ModalContainer,
.ModalBackground {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: all ease-in-out 250ms;
  border-radius: 0;
  max-width: none;

  &.FadeInAnimation {
    opacity: 0;
    transform: scale(1.05);

    &.Active {
      opacity: 1;
      transform: scale(1);
    }
  }

  @include tablet {
    border-radius: $borderRadius;
    max-height: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: auto;
    width: auto;
  }
}

.Header {
  position: relative;
  background-color: $color-white;
}

.Content {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.Close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 3;
}

.Footer{
  display: flex;
  flex-direction: column;
  padding: 13px 15px;
  background-color: white;
  border-top: 1px solid $color-black5;
}